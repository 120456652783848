<template>
  <div>
    <b-loading :is-full-page="true" v-model="loading" />
    <Header />
    <div class="h-24"></div>
    <FloatingBreadcrumbs :data="data" />
    <transition
      enter-active-class="animate__animated animate__fadeInDown animate__faster"
      leave-active-class="animate__animated animate__fadeOutUp animate__faster"
    >
      <div
        v-if="sticky && !loading"
        :class="{
          sticky: sticky
        }"
      >
        <FloatingBreadcrumbs :data="data" />
      </div>
    </transition>
    <div class="container p-4 pt-0">
      <div class="px-2">
        <div class="-mx-4 flex flex-wrap">
          <div
            class="w-1/4 mb-4 px-2"
            v-for="(item, index) in products"
            :key="index"
          >
            <ProductCard :data="item" />
          </div>
        </div>
      </div>
    </div>
    <transition
      enter-active-class="animate__animated animate__fadeInUp animate__faster"
      leave-active-class="animate__animated animate__fadeOutDown animate__faster"
    >
      <div
        v-if="products.length && products.length % 4 == 0"
        @click="getMoreProducts"
        class="more fixed bottom-0 bg-gray-200 bg-opacity-50 h-16 flex items-center justify-center w-full has-text-gold cursor-pointer is-uppercase"
      >
        Click or Scroll for More Products
      </div>
    </transition>
    <div ref="end"></div>
  </div>
</template>

<script>
import Header from "@/components/client/Header";
import ProductCard from "@/components/client/ProductCard";
import FloatingBreadcrumbs from "@/components/client/FloatingBreadcrumbs";
import store from "@/store";
export default {
  // title() {
  //   return `${this.data.name} - ${process.env.VUE_APP_TITLE}`
  // },
  data: () => ({
    loading: false,
    data: {},
    products: [],
    page: 1,
    totalPages: 1,
    sticky: false,
    previousScroll: 0
  }),
  components: {
    Header,
    ProductCard,
    FloatingBreadcrumbs
  },
  computed: {},
  watch: {
    sticky() {
      if (this.sticky) {
        setTimeout(() => {
          this.sticky = false;
        }, 5000);
      }
    },
    $route(to) {
      // this.sticky = false;
      this.loading = true;
      this.previousScroll = 0;
      this.page = 1;
      this.totalPages = 1;
      store
        .dispatch(this.$route.meta.dispatch, {
          id: this.$route.params.id,
          show: this.$route.query.show
        })
        .then(res => {
          store.commit("setBreadcrumbs", {
            name: "Home",
            label: "Home",
            index: 0
          });
          store.commit("addBreadcrumbs", {
            name: to.name,
            params: to.params,
            query: to.query || undefined,
            label: res.data.name,
            index: 1
          });
          document.title = `${res.data.name} - ${process.env.VUE_APP_TITLE}`;
          this.data = res.data;
          this.products = res.data.products;
          this.page++;
          // this.totalPages = res.data.paginated_data.last_page;
          this.totalPages = res.data.products.length ? this.page + 1 : 1;
        })
        .catch(() => {
          this.$router.push({ name: "Home" });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    data() {
      if (window.gtag) {
        gtag("event", "category_view", {
          send_to: "clients",
          category: this.data.name,
          email: this.$store.getters.activeUser.email
        });
      }
    }
  },
  methods: {
    getMoreProducts: _.debounce(function() {
      if (this.page <= this.totalPages) {
        this.loading = true;
        store
          .dispatch(this.$route.meta.dispatch, {
            id: this.$route.params.id,
            show: this.$route.query.show,
            page: this.page
          })
          .then(res => {
            res.data.products.forEach(item => this.products.push(item));
            this.page++;
            // this.totalPages = res.data.paginated_data.last_page;
            this.totalPages = res.data.products.length ? this.page + 1 : 1;
            this.loading = false;
          });
      }
    }, 250),
    onScroll() {
      if (_.ceil(document.documentElement.scrollTop) > 50) {
        var currentScroll = _.ceil(document.documentElement.scrollTop);
        if (this.previousScroll > currentScroll && !this.loading) {
          this.sticky = true;
        }
      }
      this.previousScroll = currentScroll;

      if (
        document.documentElement.scrollHeight -
          _.ceil(document.documentElement.scrollTop) <=
        window.innerHeight
      ) {
        this.getMoreProducts();
      }
    }
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeRouteEnter(to, from, next) {
    if (to.meta.dispatch) {
      store
        .dispatch(to.meta.dispatch, { id: to.params.id, show: to.query.show })
        .then(res => {
          next(vm => {
            store.commit("setBreadcrumbs", {
              name: "Home",
              label: "Home",
              index: 0
            });
            store.commit("addBreadcrumbs", {
              name: to.name,
              params: to.params,
              query: to.query || undefined,
              label: res.data.name,
              index: 1
            });
            document.title = `${res.data.name} - ${process.env.VUE_APP_TITLE}`;
            vm.data = res.data;
            vm.products = res.data.products;
            vm.page++;
            // vm.totalPages = res.data.paginated_data.last_page;
            vm.totalPages = res.data.products.length ? vm.page + 1 : 1;
          });
        })
        .catch(() => {
          next({ name: "Home" });
        });
    }
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("scroll", this.onScroll);
    next();
  }
};
</script>
